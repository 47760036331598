import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from 'react';
import styled from 'styled-components';
import useBreakpointView from 'utils/useBreakpointView';

const useDebouncedRippleCleanUp = (rippleCount, duration, cleanUpFunction) => {
  useLayoutEffect(() => {
    if (rippleCount > 0) {
      const bounce = setTimeout(() => {
        cleanUpFunction();
      }, duration * 4);
      return () => clearTimeout(bounce);
    }
  }, [rippleCount, duration, cleanUpFunction]);
};

const RippleEffect = forwardRef(({ duration = 400, color = 'rgba(255, 255, 255, 0.5)' }, ref) => {
  const [rippleArray, setRippleArray] = useState([]);
  const isMobile = useBreakpointView(['xs', 'sm']);
  const [lastInteractionType, setLastInteractionType] = useState(null);

  const cleanUpFunction = useCallback(() => {
    setRippleArray([]);
  }, []);

  useDebouncedRippleCleanUp(rippleArray.length, duration, cleanUpFunction);

  const addRipple = useCallback(
    (event) => {
      if (isMobile || (!isMobile && lastInteractionType === 'touch')) {
        return;
      }

      setLastInteractionType(isMobile ? 'touch' : 'mouse');

      if (event.type === 'contextmenu') event.preventDefault();

      let clientX;
      let clientY;

      if (event.touches && event.touches.length > 0) {
        clientX = event.touches[0].clientX;
        clientY = event.touches[0].clientY;
      } else {
        clientX = event.clientX;
        clientY = event.clientY;
      }

      const rippleContainer = event.currentTarget.getBoundingClientRect();
      const size = Math.min(rippleContainer.width, rippleContainer.height) * 1.5;
      const x = clientX - rippleContainer.left - size / 2;
      const y = clientY - rippleContainer.top - size / 2;

      const newRipple = { x, y, size, key: Date.now() };
      setRippleArray((prevRippleArray) => [...prevRippleArray, newRipple]);
    },
    [isMobile, lastInteractionType],
  );

  useImperativeHandle(ref, () => ({
    createRipple: addRipple,
  }));

  useEffect(() => {
    if (rippleArray.length > 0) {
      const timer = setTimeout(() => setRippleArray([]), duration * 4);
      return () => clearTimeout(timer);
    }
  }, [rippleArray, duration]);

  return (
    <RippleContainer
      duration={duration}
      color={color}
      onMouseDown={(e) => addRipple(e)}
      onContextMenu={(e) => addRipple(e)}
      onTouchStart={(e) => addRipple(e)}
    >
      {rippleArray.map((ripple, index) => (
        <span
          key={`ripple-${index}`}
          style={{
            top: ripple.y,
            left: ripple.x,
            width: ripple.size,
            height: ripple.size,
          }}
        />
      ))}
    </RippleContainer>
  );
});

export default RippleEffect;

const RippleContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  pointer-events: none;

  span {
    transform: scale(0);
    border-radius: 50%;
    position: absolute;
    opacity: 1;
    background-color: ${(props) => props.color};
    animation-name: ripple;
    animation-duration: ${(props) => props.duration}ms;
    will-change: transform, opacity;
  }

  @keyframes ripple {
    to {
      opacity: 0;
      transform: scale(4);
    }
  }
`;
